import React, { Component } from "react";
import navigator from "../services/navigator";
import request from "./../services/request";

export default class Config extends Component {
    render() {
        return (<div></div>);
    }
    componentDidMount() {
        request.get("/init");
        navigator.push("/v2");
    }
}
