import React, { Component } from "react";
import Typist from "react-typist";

export default class Hacking extends Component {
    constructor(){
        super();
        this.state = {text: ""};
    }
    render() {
        return (
            <div className="v1-hacking-container">
                <Typist className="v1-hacking-input" onTypingDone={() => this.setState({text: "You are in."})}
                    cursor={{hideWhenDone: true, hideWhenDoneDelay: 200}}>
                    Hacking...
                </Typist>
                <div className="v1-hacking-text">{this.state.text}</div>
            </div>
        );
    }
}
