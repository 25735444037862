import React, { Component } from "react";

export default class BlueScreenOfDeath extends Component {
    render() {
        return (
            <div className="v2-bluescreen">
                <div className="v2-bluescreen-text-container">
                    <div className="v2-bluescreen-title">
                        Error: Screen too small
                    </div>
                    <br />
                    <br />
                    <div className="v2-bluescreen-text">
                        Since this website is Windows XP themed, please resize your screen. If you are using a phone, please use this website on a bigger screen.
                    </div>
                </div>
            </div>
        );
    }
}
