import React, { Component } from "react";
import {Grid} from "@material-ui/core/";
import {withRouter} from 'react-router';
import AnimateCSS from "animate.css"
import LandingImage from "../../components/v1/LandingImage";
import Game from "../../game/Game";
import Thanos from "../../thanos/Thanos";
import Footer from "../../components/v1/Footer";

export default class Random extends Component {
    constructor(){
        super();
        this.state = {animation: ""};
    }
    render() {
        return (
            <div>
                <div className={this.state.animation}>
                    <LandingImage />
                </div>
                <div className="v1-content">
                    <Grid container direction="column" justify="center" className="v1-section">
                        <div className="v1-subtitle">Game</div>
                        <Grid container direction="row" justify="center">
                            <Game style={{width: 800, height: 800}}/>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" justify="center" className="v1-section">
                        <div className="v1-subtitle">Thanos</div>
                        <Grid container direction="row" justify="center">
                            <Thanos />
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </div>
        );
    }
    componentDidMount() {
        this.setState({animation: "animated fadeInUp fast"});
    }
    routerWillLeave(nextState) {
        if (nextState.action === "POP") {
            this.setState({animation: "animated fadeOutDown fast"});
        }
    }
}
