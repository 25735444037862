import React, { Component } from "react";

export default class TaskbarTab extends Component {
    render() {
        return (
            <button className="v2-taskbar-tab" onClick={this.props.click}
                style={this.props.focused?{filter: "brightness(65%)"}:{}}>
                <img className="v2-taskbar-tab-icon"
                src={process.env.PUBLIC_URL + "/desktop/" + this.props.icon}/>
                <div className="v2-taskbar-tab-text">{this.props.text}</div>
            </button>
        );
    }
}
