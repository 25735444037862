import React, { Component } from "react";
import {Grid} from "@material-ui/core/";
import {VerticalTimeline, VerticalTimelineElement}  from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default class TimeLine extends Component {
    render() {
        let icon = {background: "#00a1ff", height: 30, width: 30, top: 10, right: 2};
        return (
            <Grid container direction="column" justify="center" className="v1-section">
                <div className="v1-title">Time Line</div>
                <VerticalTimeline>
                    <VerticalTimelineElement iconStyle={icon}>
                        <div className="v1-timeline-text">
                            Start studying in University of Western Ontario
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement iconStyle={icon}>
                        <div className="v1-timeline-text">
                            Volunteer at a startup called Lawyerly
                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement iconStyle={icon}>
                        <div className="v1-timeline-text">
                            Attended my first hackathon and won third (Hack Western 5)
                        </div>
                    </VerticalTimelineElement>
                </VerticalTimeline>
            </Grid>
        );
    }
}
