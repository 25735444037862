import Phaser from "phaser";

export default class GamePlay extends Phaser.Scene{
    constructor() {
        super({key: "GamePlay"});
    }
    preload() {
        this.load.spritesheet("player", process.env.PUBLIC_URL + "/game/player.png", {
            frameWidth: 32, frameHeight: 32
        });
        this.load.image("coin", process.env.PUBLIC_URL + "/game/coin.png");
        this.load.image("platform1", process.env.PUBLIC_URL + "/game/platform1.png");
        this.load.image("platform2", process.env.PUBLIC_URL + "/game/platform2.png");
        this.load.image("platform3", process.env.PUBLIC_URL + "/game/platform3.png");
        this.load.image("platform4", process.env.PUBLIC_URL + "/game/platform4.png");
        this.load.image("platform5", process.env.PUBLIC_URL + "/game/platform5.png");
        this.load.image("background", process.env.PUBLIC_URL + "/game/background.png");
    }
    create() {
        this.settings = {
            width: 400,
            height: 400,
            jumpsAllowed: 1,
            gravity: 400,
            jump: 200,
            speed: 150,
            spawnRange: [150, 180],
            platformRange: [1, 4],
            platformSize: 64,
            startPosition: 100
        };
        this.platformGroup = this.add.group({
            removeCallback: function(platform){
                platform.scene.platformPool.add(platform);
            }
        });
        this.platformPool = this.add.group({
            removeCallback: function(platform){
                platform.scene.platformGroup.add(platform)
            }
        });

        this.playerJumps = 0;
        this.playerRuns = false;
        this.score = 0;
        this.paused = false;

        this.background = this.add.tileSprite(0, 0, 1600, 800, "background");
        this.background.tileScaleX = 0.5;
        this.background.tileScaleY = 0.5;

        this.scoreboard = this.add.text(0, 0, "Score: 0", {fill: "#ffffff",fontSize: 12,});

        this.player = this.physics.add.sprite(this.settings.startPosition, this.settings.height / 2, "player", 0);
        this.player.displayWidth = 100;
        this.player.displayHeight = 100;
        this.player.setSize(20, 20, false);
        this.player.body.offset.x = 5;
        this.player.body.offset.y = 10;
        this.player.setGravityY(this.settings.gravity);
        this.player.setPosition(0, 250);
        this.physics.add.collider(this.player, this.platformGroup);
        this.addPlatform(5, this.settings.width / 2);
        this.anims.create({
            key: "running",
            repeat: -1,
            framerate: 1,
            frames: this.anims.generateFrameNames("player", {start: 13, end: 17})
        });
        this.anims.create({
            key: "jumping",
            repeat: 1,
            framerate: 1,
            frames: this.anims.generateFrameNames("player", {start: 68, end: 69})
        });
        this.player.play("running");
        this.input.on("pointerdown", this.jump, this);

        setInterval(() => {
            if (this.paused) {
                this.score = 0;
                this.scoreboard.setText("Score: " + 0);
            } else {
                this.score++;
                this.scoreboard.setText("Score: " + this.score);
            }
        }, 200);
    }
    jump(){
           if(this.player.body.touching.down || (this.playerJumps > 0 && this.playerJumps < this.settings.jumpsAllowed)){
           if(this.player.body.touching.down) this.playerJumps = 0;
           this.playerRuns = false;
           this.player.anims.stop();
           this.player.play("jumping");
           this.player.setVelocityY(this.settings.jump * -1);
           this.playerJumps++;
       }
   }
   addPlatform(platformType, positionX){
        let platform;
        if(this.platformPool.getLength()){
            platform = this.platformPool.getFirst();
            platform.x = positionX;
            platform.active = true;
            platform.visible = true;
            this.platformPool.remove(platform);
        } else{
            platform = this.physics.add.sprite(positionX, this.settings.height, "");
            platform.setImmovable(true);
            platform.setVelocityX(this.settings.speed * -1);
            this.platformGroup.add(platform);
        }
        platform.setTexture("platform"+platformType);
        platform.setSize(platformType * this.settings.platformSize);
        platform.displayWidth = platformType * this.settings.platformSize;
        this.nextPlatformDistance = Phaser.Math.Between(this.settings.spawnRange[0], this.settings.spawnRange[1]);
    }
    update(){

        if (this.player.y > this.settings.height * 1.1) {
            this.paused = true;
            this.scene.launch("GameOver",  {score: this.score});
            this.scene.pause("GamePlay");
        };
        this.background.tilePositionX += 2;
        if (this.player.body.touching.down && this.playerRuns === false) {
            this.playerRuns = true;
            this.player.anims.stop();
            this.player.play("running");
        }
        this.player.x = this.settings.startPosition;
        let minDistance = this.settings.width;
        this.platformGroup.getChildren().forEach((platform) => {
            let platformDistance = this.settings.width - platform.x - platform.displayWidth / 2;
            minDistance = Math.min(minDistance, platformDistance);
            if(platform.x < - platform.displayWidth / 2){
                this.platformGroup.killAndHide(platform);
                this.platformGroup.remove(platform);
            }
        });
        if(minDistance > this.nextPlatformDistance){
            let nextPlatformType = Phaser.Math.Between(this.settings.platformRange[0], this.settings.platformRange[1]);
            this.addPlatform(nextPlatformType, this.settings.width + nextPlatformType * this.settings.platformSize / 2);

        }
    }
}