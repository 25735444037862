import Phaser from "phaser";

export default class MainMenu extends Phaser.Scene{
    constructor() {
        super({key: "MainMenu"});
    }
    preload() {
        this.load.spritesheet("player", process.env.PUBLIC_URL + "/game/player.png", {frameWidth: 32, frameHeight: 32});
        this.load.image("main", process.env.PUBLIC_URL + "/game/main.png");
    }
    create() {
        this.add.image(0, 180, "main");
        const titleOne = this.add.text(0, 0, "Endless", {
            fill: "#ffffff",
            fontSize: 48,
        });
        const titleTwo = this.add.text(0, 0, "Runner", {
            fill: "#ffffff",
            fontSize: 48,
        });
        const play = this.add.text(0, 0, "<Play>", {
            fill: "#ffffff",
            fontSize: 36,
        });
        const player = this.add.sprite(0, 0, "player", 14);
        player.displayWidth = 125;
        player.displayHeight = 125;
        player.setPosition(260-player.displayWidth/2, 200);
        titleOne.setPosition(200-titleOne.displayWidth/2, 70);
        titleTwo.setPosition(200-titleTwo.displayWidth/2, 100);
        play.setPosition(200-play.displayWidth/2, 300);
        play.setInteractive({useHandCursor: true}).on('pointerdown', () => {
            this.scene.start("GamePlay");
            this.scene.stop("MainMenu");
        });
    }
}