import React, { Component } from "react";
import ExperienceCard from "./ExperienceCard";
import info from "../../data/info";

export default class Projects extends Component {
    render() {
        return (
            <div className="v2-experiences">
                <div className="v2-title">
                    Personal Projects
                </div>
                <ExperienceCard links={info.links.comparely}
                    image={info.images.comparely} title={info.projectNames.comparely}
                    description={info.descriptions.comparely} />
                <ExperienceCard links={info.links.chattybox}
                    image={info.images.chattybox} title={info.projectNames.chattybox}
                    description={info.descriptions.chattybox} />
                <ExperienceCard links={info.links.drawanything}
                    image={info.images.drawanything} title={info.projectNames.drawanything} 
                    subtitle={info.projectSignificances.drawanything}
                    description={info.descriptions.drawanything} />
                <ExperienceCard links={info.links.mathsbot}
                    image={info.images.mathsbot} title={info.projectNames.mathsbot} 
                    subtitle={info.projectSignificances.mathsbot}
                    description={info.descriptions.mathsbot} />
                <div className="v2-title">
                    Hackathon Wins
                </div>
                <ExperienceCard links={info.links.pocketqr}
                    image={info.images.pocketqr} title={info.projectNames.pocketqr} 
                    subtitle={info.projectSignificances.pocketqr}
                    description={info.descriptions.pocketqr} />
                <ExperienceCard links={info.links.tenty}
                    image={info.images.tenty} title={info.projectNames.tenty} 
                    subtitle={info.projectSignificances.tenty}
                    description={info.descriptions.tenty} />
            </div>
        );
    }

}
