import React, { Component } from "react";
import Shortcut from "../../components/v2/Shortcut";
import Taskbar from "../../components/v2/Taskbar";
import Window from "../../components/v2/Window";
import StartMenu from "../../components/v2/StartMenu";
import About from "../../components/v2/About";
import Experiences from "../../components/v2/Experiences";
import Projects from "../../components/v2/Projects";
import Contact from "../../components/v2/Contact";
import BlueScreenOfDeath from "../../components/v2/BlueScreenOfDeath";
import Game from "../../game/Game";
import Thanos from "../../thanos/Thanos";

export default class Home extends Component {
    constructor(){
        super();
        this.state = {
            desktopWidth: 0,
            desktopHeight: 0,
            start: "close",
            inUseApps: [],
            displayOrder: []
        };
        this.apps = {
            about: {
                title: "About",
                icon: "about.png",
                body: (<About />),
                height: 600,
                width: 800
            },
            experiences: {
                title: "Experiences",
                icon: "experiences.png",
                body: (<Experiences />),
                height: 600,
                width: 1000
            },
            projects: {
                title: "Projects",
                icon: "projects.png",
                body: (<Projects />),
                height: 600,
                width: 1000
            },
            game: {
                title: "Endless Runner",
                icon: "game.png",
                body: (<Game />),
                height: 600,
                width: 600
            },
            // thanos: {
            //     title: "Thanos",
            //     icon: "thanos.png",
            //     body: (<Thanos />),
            //     height: 600,
            //     width: 600
            // },
            contact: {
                title: "Contact",
                icon: "contact.png",
                body: (<Contact />),
                height: 600,
                width: 750
            },
            github: {
                title: "Github",
                icon: "github.png",
                link: "https://github.com/CyaTmrw"
            },
            devpost: {
                title: "Devpost",
                icon: "devpost.png",
                link: "https://devpost.com/CyaTmrw"
            },
            oldsite: {
                title: "Older Version",
                icon: "old-site.png",
                link: "/v1"
            }
        };

    }
    render() {
        if (this.state.desktopWidth < 700 || this.state.desktopHeight < 700) {
            return (<BlueScreenOfDeath />);
        }

        let Apps = this.state.inUseApps.map((app, index) => {
            if (app.status === "open" || app.status === "minimize") {
                return (
                    <Window key={index} title={this.apps[app.name].title} icon={this.apps[app.name].icon}
                        focused={this.state.displayOrder[this.state.displayOrder.length - 1] === app.name}
                        desktopHeight={this.state.desktopHeight} desktopWidth={this.state.desktopWidth}
                        appHeight={this.apps[app.name].height} appWidth={this.apps[app.name].width}
                        status={app.status} overlay={this.state.displayOrder.indexOf(app.name)} minimize={() => this.minimize(index)} close={() => this.close(index)}>
                        {this.apps[app.name].body}
                    </Window>
                );
            }

        });
        let StartMenuWindow;
        if (this.state.start === "open") StartMenuWindow = (
            <StartMenu click={(name) => this.open(name)}
            apps={[
                {name: "about", title: this.apps["about"].title, icon: this.apps["about"].icon},
                {name: "contact", title: this.apps["contact"].title, icon: this.apps["contact"].icon}
            ]}
            links ={[
                {name: "github", title: this.apps["github"].title, icon: this.apps["github"].icon},
                {name: "devpost", title: this.apps["devpost"].title, icon: this.apps["devpost"].icon},
                {name: "oldsite", title: this.apps["oldsite"].title, icon: this.apps["oldsite"].icon}
            ]}
            />
        );
        else StartMenuWindow = null;

        return (
            <div className="v2-app-container">
                <div className="v2-desktop">
                    <div className="v2-shortcuts-container">
                        <Shortcut icon={this.apps["about"].icon} text={this.apps["about"].title}
                        click={() => this.open("about")}/>
                        <Shortcut icon={this.apps["experiences"].icon} text={this.apps["experiences"].title}
                        click={() => this.open("experiences")}/>
                        <Shortcut icon={this.apps["projects"].icon} text={this.apps["projects"].title}
                        click={() => this.open("projects")}/>
                        <Shortcut icon={this.apps["game"].icon} text="Game"
                        click={() => this.open("game")}/>
                        {/* <Shortcut icon={this.apps["thanos"].icon} text={this.apps["thanos"].title}
                        click={() => this.open("thanos")}/> */}
                        <Shortcut icon={this.apps["contact"].icon} text={this.apps["contact"].title}
                        click={() => this.open("contact")}/>
                        <Shortcut icon={this.apps["github"].icon} text={this.apps["github"].title}
                        click={() => this.open("github")}/>
                        <Shortcut icon={this.apps["devpost"].icon} text={this.apps["devpost"].title}
                        click={() => this.open("devpost")}/>
                        <Shortcut icon={this.apps["oldsite"].icon} text={this.apps["oldsite"].title}
                        click={() => this.open("oldsite")}/>
                    </div>
                    {Apps}
                    {StartMenuWindow}
                </div>

                <Taskbar toggleStart={() => this.setState({start: (this.state.start === "open") ? "close" : "open"})}
                focusedApp={this.state.displayOrder[this.state.displayOrder.length - 1]}
                apps={this.state.inUseApps.map((app) => ({name: app.name, title: this.apps[app.name].title, icon: this.apps[app.name].icon, status: app.status}))}
                tabOnClick={(index) => this.tabOnClick(index)}/>
            </div>
        );
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', () => this.updateWindowDimensions());
    }
    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateWindowDimensions());
    }
    updateWindowDimensions() {
        this.setState({desktopWidth: window.innerWidth, desktopHeight: window.innerHeight});
    }
    open(name) {
        if (this.apps[name].link !== undefined) {
            window.open(this.apps[name].link, "_blank")
        } else {
            for (let i = 0; i < this.state.inUseApps.length; i++) {
                if (this.state.inUseApps[i].name === name) {
                    this.tabOnClick(i);
                    return;
                }
            }
            this.setState((previousState) => {
                let inUseApps = previousState.inUseApps;
                let displayOrder = previousState.displayOrder;
                inUseApps.push({
                    name,
                    status: "open"
                });
                displayOrder.push(name);
                return {...previousState, inUseApps, displayOrder, start: "close"};
            });
        }

    }
    tabOnClick(index) {
        this.setState(previousState => {
            let inUseApps = previousState.inUseApps;
            let displayOrder = previousState.displayOrder;
            let name = inUseApps[index].name;
            inUseApps[index].status = "open";
            if (displayOrder.indexOf(name) === -1) {
                displayOrder.push(name);
            } else {
                displayOrder.push(name);
                displayOrder.splice(displayOrder.indexOf(name), 1);
            }
            return {...previousState, inUseApps, displayOrder};
        });
    }
    minimize(index) {
        this.setState(previousState => {
            let inUseApps = previousState.inUseApps;
            let displayOrder = previousState.displayOrder;
            let name = inUseApps[index].name;
            inUseApps[index].status = "minimize";
            displayOrder.splice(displayOrder.indexOf(name), 1);
            return {...previousState, inUseApps, displayOrder};
        });
    }
    close(index) {
        this.setState(previousState => {
            let inUseApps = previousState.inUseApps;
            let displayOrder = previousState.displayOrder;
            let name = inUseApps[index].name;
            inUseApps.splice(index, 1);
            displayOrder.splice(displayOrder.indexOf(name), 1);
            return {...previousState, inUseApps, displayOrder};
        });
    }
}
