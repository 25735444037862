import React, { Component } from "react";
import TaskbarTab from "../../components/v2/TaskbarTab";

export default class Taskbar extends Component {
    constructor(){
        super();
        this.state = {time: null};
    }
    render() {
        let tabs = this.props.apps.map((app, index) => {
            return (
                <TaskbarTab key={index} icon={app.icon} text={app.title} click={() => this.props.tabOnClick(index)} focused={this.props.focusedApp === app.name}/>
            );
        });
        return (
            <div className="v2-taskbar">
                <button className="v2-start-button" onClick={this.props.toggleStart}>
                    <img src={process.env.PUBLIC_URL + "/desktop/start-button.png"}
                    className="v2-start-button-icon"/>
                    Start
                </button>
                <div className="v2-taskbar-tabs-container">
                    {tabs}
                </div>
                <div className="v2-system-tray">
                    <div className="v2-time">
                        {this.state.time}
                    </div>
                </div>
            </div>
        );
    }
    componentDidMount() {
        this.time();
    }
    time() {
        setInterval(() => {
            let date = new Date();
            let minute = date.getMinutes();
            let hour = date.getHours();
            if (minute < 10) {minute = "0" + minute}
            if (hour < 10) {hour = "0" + hour}
            this.setState({time: hour + ":" + minute})
        }, 1000);
    }
}
