import Phaser from "phaser";

export default class GameOver extends Phaser.Scene{
    constructor() {
        super({key: "GameOver"});
    }
    init(data) {
        this.score = data.score
    }
    preload() {
        this.load.spritesheet("player", process.env.PUBLIC_URL + "/game/player.png", {frameWidth: 32, frameHeight: 32});
    }
    create() {
        const gameOver = this.add.text(0, 0, "Game Over!", {
            fill: "#ffffff",
            fontSize: 48,
        });
        const playAgain = this.add.text(0, 0, "<Play Again>", {
            fill: "#ffffff",
            fontSize: 24,
        });
        const score = this.add.text(0, 0, "", {
            fill: "#ffffff",
            fontSize: 24,
        });
        const player = this.add.sprite(0, 0, "player", 97);
        player.displayWidth = 125;
        player.displayHeight = 125;
        player.setPosition(250-player.displayWidth/2, 200);
        gameOver.setPosition(200-gameOver.displayWidth/2, 100);
        playAgain.setPosition(200-playAgain.displayWidth/2, 300);
        playAgain.setInteractive({useHandCursor: true}).on('pointerdown', () => {
            this.scene.start("GamePlay");
        });
        score.setText("Score: " + this.score);
        score.setPosition(200-score.displayWidth/2, 175);
    }
}