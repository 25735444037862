import React, { Component } from "react";

export default class ExperienceCard extends Component {
    render() {
        let buttons = this.props.links.map((link, index) => (
            <button key={index} className="v2-experience-card-button button" onClick={() => window.open(link.url, "_blank")}>
                {link.label}
            </button>
        ));

        let description = this.props.description;

        if (Array.isArray(this.props.description)) {
            description = this.props.description.map((text, index) => (
                <span key={index}>
                  - {text} <br/>
                </span>
            ));
        }

        return (
            <div className="v2-experience-card">

                <div className="v2-experience-card-text-container">
                    <div className="v2-experience-card-header-container">
                        {
                            this.props.image && 
                            <img src={process.env.PUBLIC_URL + "/static/" + this.props.image}
                                className="v2-experience-card-image"/>
                        }
                        
                        <div className="v2-experience-card-header-text-container">
                            <div className="v2-experience-card-title">{this.props.title}</div>
                            <div className="v2-experience-card-date">
                                {this.props.date}
                            </div>
                            <div className="v2-experience-card-subtitle">{this.props.subtitle}</div>
                            <div className="v2-experience-card-buttons-container">
                                {buttons}
                            </div>
                        </div>
                    </div>

                    <div className="v2-experience-card-description">{description}</div>
                </div>
            </div>
        );
    }
}
