import React, { Component } from "react";
import {Grid} from "@material-ui/core";
import ExperienceCard from "./ExperienceCard";
import info from "../../data/info";

export default class Projects extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" className="v1-section">
                <div className="v1-subtitle">Projects</div>
                <div className="v1-subtitle-2">Personal Projects</div>
                <Grid container direction="column" alignItems="center">
                    <ExperienceCard links={info.links.comparely}
                        image={info.images.comparely} title={info.projectNames.comparely}
                        description={info.descriptions.comparely} />
                    <ExperienceCard links={info.links.chattybox}
                        image={info.images.chattybox} title={info.projectNames.chattybox}
                        description={info.descriptions.chattybox} />
                    <ExperienceCard links={info.links.drawanything}
                        image={info.images.drawanything} title={info.projectNames.drawanything} 
                        subtitle={info.projectSignificances.drawanything}
                        description={info.descriptions.drawanything} />
                    <ExperienceCard links={info.links.mathsbot}
                        image={info.images.mathsbot} title={info.projectNames.mathsbot} 
                        subtitle={info.projectSignificances.mathsbot}
                        description={info.descriptions.mathsbot} />
                </Grid>
                <br/>
                <br/>
                <div className="v1-subtitle-2">Hackathon Wins</div>
                <Grid container direction="column" alignItems="center">
                    <ExperienceCard links={info.links.pocketqr}
                        image={info.images.pocketqr} title={info.projectNames.pocketqr} 
                        subtitle={info.projectSignificances.pocketqr}
                        description={info.descriptions.pocketqr} />
                    <ExperienceCard links={info.links.tenty}
                        image={info.images.tenty} title={info.projectNames.tenty} 
                        subtitle={info.projectSignificances.tenty}
                        description={info.descriptions.tenty} />
                </Grid>
            </Grid>
        );
    }

}
