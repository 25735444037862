import React, { Component } from "react";
import Typist from "react-typist";
import NavBar from "../../components/v1/NavBar";

export default class LandingVideo extends Component {
    constructor(){
        super();
        this.state = {typing: true, counter: 0, list: ["web", "mobile", "fullstack"]};
    }
    render() {
        return (
            <div className="v1-landing-video-container">
                <NavBar scroll={(value) => this.props.scroll(value)}
                animate={(value) => this.props.animate(value)}/>
                <div className="v1-landing-video-inner">
                    <video loop autoPlay muted className="v1-landing-video">
                        <source src={process.env.PUBLIC_URL + "/static/landing-video.mp4"} type="video/mp4"/>
                    </video>
                </div>

                {/*<img className="v1-landing-image" src={process.env.PUBLIC_URL + "/static/landing-image.jpg"} />*/}
                <div className="v1-landing-content">
                    My name is Ivan Zhang
                    <br/>
                    and I am interested in
                    {this.state.typing ?
                        <Typist avgTypingDelay={100} cursor={{hideWhenDone: true, hideWhenDoneDelay: 200}}
                            className="v1-dynamic-text v1-primary-text-color"
                            onTypingDone={() => this.done()}>
                            {this.state.list[this.state.counter]}
                        </Typist> : ''
                    }
                    development.
                </div>


            </div>
        );
    }
    done() {
        setTimeout(() => {
            if (this.state.counter === this.state.list.length - 1) {
                this.setState({counter: 0})
            } else {
                this.setState({counter: this.state.counter + 1})
            }
            this.setState({ typing: false }, () => {
                this.setState({ typing: true })
            });
        }, 1000);

    }
}
