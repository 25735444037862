import React, { Component } from "react";
import ExperienceCard from "./ExperienceCard";
import info from "../../data/info";

export default class Experiences extends Component {
    render() {
        return (
            <div className="v2-experiences">
                <ExperienceCard links={info.links.hubinternational} 
                    title={info.companyNames.hubinternational} subtitle={info.jobTitles.hubinternational}
                    date={info.dates.hubinternational} description={info.descriptions.hubinternational} />
                <ExperienceCard links={info.links.tulip}
                    title={info.companyNames.tulip} subtitle={info.jobTitles.tulip}
                    date={info.dates.tulip} description={info.descriptions.tulip} />
                <ExperienceCard links={info.links.freelance}
                    title={info.companyNames.freelance} subtitle={info.jobTitles.freelance}
                    date={info.dates.freelance} description={info.descriptions.freelance}/>
            </div>
        );
    }

}
