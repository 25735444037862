import React, { Component } from "react";
import {Grid} from "@material-ui/core/";
import $ from "jquery";
import html2canvas from "html2canvas";
import Chance from "chance";

export default class Thanos extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center">
                <Grid container direction="row" justify="center">
                    Click on the Infinity Gauntlet and wait.
                </Grid>
                <br />
                <div className="thanos">
                    <div>
                        <img src={process.env.PUBLIC_URL + "/thanos/thanos.png"} height="150"/>
                        <button className="snap">
                            <img src={process.env.PUBLIC_URL + "/thanos/infinity_gauntlet.png"} height="100"/>
                        </button>
                    </div>
                    <div className="universe">
                        <div className="universe-part">
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/dr_strange.png"} height="125"/>
                            </div>
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/iron_man.png"} height="125"/>
                            </div>
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/spider_man.png"} height="125"/>
                            </div>
                        </div>
                        <div className="universe-part">
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/black_panther.png"} height="125"/>
                            </div>
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/thor.png"} height="125"/>
                            </div>
                            <div className="person">
                                <img src={process.env.PUBLIC_URL + "/thanos/captain_america.png"} height="125"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Grid>
        );
    }
    componentDidMount() {
        let snapped = false;
        let chance = new Chance();
        $(".snap").click(function() {
            if(snapped === true) return;
            console.log("Snapped");
            snapped = true;
            let timerArray = [];
            let timerCounter = -1;
            let numberOfPeople = 0;
            let peopleDiedCounter = 0;
            let currentPerson = 0;
            $(".universe").children(".universe-part").children(".person").each(function () {
                let timer = Math.random() * 1000;
                timerArray.push(timer);
                numberOfPeople++;
            });
            $(".universe").children(".universe-part").children(".person").each(function () {
                html2canvas($(this)[0]).then(canvas => {
                    let imageDataArray = [];
                    let canvasCount = 20;
                    let probabilityOfDying = Math.random();
                    currentPerson++;
                    if (probabilityOfDying > 0.5 ||
                        (currentPerson > numberOfPeople/2 && peopleDiedCounter < numberOfPeople/2)) {
                        if (peopleDiedCounter === numberOfPeople/2) return;
                        console.log("died");
                        timerCounter++;
                        peopleDiedCounter++;
                        setTimeout(() => {
                            //capture all div data as image
                            let ctx = canvas.getContext("2d");
                            let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                            let pixelArray = imageData.data;
                            for (let i = 0; i < canvasCount; i++) {
                                let arr = new Uint8ClampedArray(imageData.data);
                                for (let j = 0; j < arr.length; j++) arr[j] = 0;
                                imageDataArray.push(arr);
                            }
                            //put pixel info to imageDataArray (Weighted Distributed)
                            for (let i = 0; i < pixelArray.length; i += 16) {
                                //find the highest probability canvas the pixel should be in
                                let peak = Math.floor((i / pixelArray.length) * canvasCount);
                                let probability = []
                                let sequence = [];
                                for (let i = 0; i < canvasCount; i++) {
                                    probability.push(Math.pow(canvasCount - Math.abs(peak - i), 3));
                                    sequence.push(i);
                                }
                                let weightedRandomDistrib = chance.weighted(sequence, probability);
                                let a = imageDataArray[weightedRandomDistrib];
                                a[i] = pixelArray[i];
                                a[i + 1] = pixelArray[i + 1];
                                a[i + 2] = pixelArray[i + 2];
                                a[i + 3] = pixelArray[i + 3];
                            }
                            //create canvas for each imageData and append to target element
                            for (let i = 0; i < canvasCount; i++) {
                                let newCanvas = document.createElement("canvas");
                                newCanvas.width = canvas.width;
                                newCanvas.height = canvas.height;
                                let tempCtx = newCanvas.getContext("2d");
                                tempCtx.putImageData(new ImageData(imageDataArray[i], canvas.width, canvas.height), 0, 0);
                                newCanvas.classList.add("dust");
                                $(this)[0].append(newCanvas);
                            }

                            //clear all children except the canvas
                            let imagHeight = $(this).children("img").height();
                            let imageWidth = $(this).children("img").width();
                            $(this).css({"height": imagHeight, "width": imageWidth});
                            $(this).children().not(".dust").fadeOut(1000);

                            //apply animation
                            $(".dust").each(function(index) {
                                let element = $(this)
                                $({rad: 0}).animate({rad: 0.9}, {
                                    duration: 10000,
                                    easing: "linear",
                                    step: function(now) {
                                        element.css({filter: "blur(" + now + "px)"});
                                    }
                                });

                                setTimeout(() => {
                                    let td = 0;
                                    let tx = 0;
                                    let ty = 0;
                                    $({x: 0, y: 0, deg: 0}).animate({
                                        x: -500,
                                        y: chance.integer({min: -500, max: 500}),
                                        deg: chance.integer({min: -100, max: 100})
                                    }, {
                                        duration: 10000 + (110 * index),
                                        easing: "linear",
                                        step: function(now, fx) {
                                            if (fx.prop === "x") tx = -now;
                                            else if (fx.prop === "y") ty = -now;
                                            else if (fx.prop === "deg") td = -now;
                                            element.css({
                                                transform: "rotate(" + td + "deg)" + "translate(" + tx + "px," + ty + "px)"
                                            });
                                        }
                                    });
                                }, 60 * index);

                                //remove the canvas from DOM tree when faded
                                $(this).delay(60 * index).fadeOut((100 * index), "linear", () => {
                                    $(this).remove();
                                });
                            });
                        }, timerArray[timerCounter]);
                    }
                });
            });
        });
    }
}
