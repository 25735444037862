import React, {Component} from "react";

export default class Message extends Component {
    render() {
        if (this.props.status === "success") {
            return (
                <div className="v1-status-message v1-success">
                    {this.props.message}
                </div>
            );
        } else if (this.props.status === "error") {
            return (
                <div className="v1-status-message v1-error">
                    {this.props.message}
                </div>
            );
        }
    }
}
