import React, { Component } from "react";
import AnimateCSS from "animate.css"
import Hacking from "../../components/v1/Hacking";
import LandingVideo from "../../components/v1/LandingVideo";
import About from "../../components/v1/About";
import TimeLine from "../../components/v1/TimeLine";
import Experiences from "../../components/v1/Experiences";
import Projects from "../../components/v1/Projects";
import Contact from "../../components/v1/Contact";
import Footer from "../../components/v1/Footer";

export default class Home extends Component {
    constructor(){
        super();
        this.state = {splash: true, animation: ""};
        this.about = React.createRef();
        this.timeline = React.createRef();
        this.experiences = React.createRef();
        this.projects = React.createRef();
        this.contact = React.createRef();
    }
    render() {
        /*
        let HackingComponent;
        let MainComponent;
        if (this.state.splash === false) {
            HackingComponent = null;
            MainComponent = (
                <div>
                    <Landing />
                    <div className="v1-content">
                        <About />
                        <Projects />
                        <Contact />
                    </div>
                    <Footer />
                </div>
            );
        } else {
            HackingComponent = (<Hacking />);
            MainComponent = null;
        }
        */
        return (
            <div className={this.state.animation}>
                <LandingVideo scroll={(value) => this.scroll(value)}
                    animate={(value) => this.animate(value)}/>
                <div className="v1-content">
                    <div ref={this.about}>
                        <About />
                    </div>
                    {/* <div ref={this.timeline}>
                        <TimeLine />
                    </div> */}
                    <div ref={this.experiences}>
                        <Experiences />
                    </div>
                    <div ref={this.projects}>
                        <Projects />
                    </div>
                    <div ref={this.contact}>
                        <Contact />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    componentDidMount() {
        this.setState({animation: "animated fadeInUp fast"});
        setTimeout(() => this.setState({splash: false}), 2000);
    }
    scroll(value) {
        this[value].current.scrollIntoView({behavior: 'smooth', block: 'start'});
    }
    animate(value) {
        if (value === "out") this.setState({animation: "animated fadeOutDown fast"});
    }
}
