import React, { Component } from "react";
import Phaser from "phaser";
import MainMenu from "./MainMenu";
import GamePlay from "./GamePlay";
import GameOver from "./GameOver";

export default class Game extends Component {
    constructor(props){
        super(props);
        this.game = null;
        this.gameElement = React.createRef();
    }
    render() {
        return (
            <div id="game" className="game" style={this.props.style?this.props.style:{}} ref={this.gameElement}></div>
        );
    }
    componentDidMount() {
        this.game = new Phaser.Game({
            type: Phaser.AUTO,
            width: 400,
            height: 400,
            parent: "game",
            pixelArt: true,
            zoom: this.gameElement.current.offsetHeight/400,
            physics: {
                default: "arcade",
                arcade: {
                    debug: false
                }
            },
            scene: [MainMenu, GamePlay, GameOver]
        });
        window.addEventListener('resize', () => this.resize());
    }
    componentWillUnmount() {
        window.removeEventListener('resize', () => this.resize());
        this.game.destroy(true, false);
    }
    resize() {
        this.game.scale.resize(400, 400);
    }
}
