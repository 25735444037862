import React, { Component } from "react";
import {
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow
} from "@material-ui/core";
import request from "./../services/request";
import navigator from "./../services/navigator";

export default class Admin extends Component {
    constructor(){
        super();
        this.state = {traffic: []};
    }
    render() {
        return (
            <div className="v1-content">
                <Grid container direction="row" justify="flex-end">
                    <Button variant="outlined" className="v1-button admin-button"
                    onClick={() => this.reset()}>
                        Reset
                    </Button>
                    <Button variant="outlined" className="v1-button admin-button"
                    onClick={() => this.logout()}>
                        Logout
                    </Button>
                </Grid>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                IP Address
                            </TableCell>
                            <TableCell>
                                City
                            </TableCell>
                            <TableCell>
                                Country
                            </TableCell>
                            <TableCell>
                                Time
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.traffic.map((traffic, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {traffic.ip}
                                        </TableCell>
                                        <TableCell>
                                            {traffic.city}
                                        </TableCell>
                                        <TableCell>
                                            {traffic.country}
                                        </TableCell>
                                        <TableCell>
                                            {traffic.time}
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>

            </div>
        )
    }
    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) {
            request.get("/admin/traffic").then((response) => {
                this.setState({traffic: response.data});
            });
        } else {
            navigator.push("/admin/login");
        }
    }
    reset() {
        request.delete("/admin/traffic").then((response) => {
            request.get("/admin/traffic").then((response) => {
                this.setState({traffic: response.data});
            });
        });
    }
    logout() {
        request.post("/admin/logout").then((response) => {
            localStorage.removeItem('token');
            navigator.push("/admin/login");
        });
    }
}
