import React, { Component } from "react";

export default class Shortcut extends Component {
    render() {
        return (
            <button className="v2-shortcut" onClick={this.props.click}>
                <img src={process.env.PUBLIC_URL + "/desktop/" + this.props.icon}
                className="v2-shortcut-icon"/>
                <div className="v2-shortcut-text" style={(this.props.text.length > 16)?{fontSize: 10}:{}}>{this.props.text}</div>
            </button>
        );
    }
}
