import React, { Component } from "react";

export default class Window extends Component {
    render() {
        let display;
        if (this.props.status === "minimize") display = "none";
        else display = "block";

        let appHeight = this.props.appHeight;
        let appWidth = this.props.appWidth;
        let heightToWidthRatio = appWidth / appHeight;
        let widthToHeightRatio = appHeight / appWidth;

        if (this.props.desktopHeight - 88 < this.props.appHeight) {
            appHeight = this.props.desktopHeight - 88;
            appWidth = appHeight * heightToWidthRatio;
        } else if (this.props.desktopWidth < this.props.appWidth) {
            appWidth = this.props.desktopWidth - 8;
            appHeight = appWidth * widthToHeightRatio;
        }

        return (
            <div className="v2-window" style={{
                height: appHeight + 30, width: appWidth, display, zIndex: 10 + this.props.overlay
            }}>
                <div className="v2-window-title-bar" style={this.props.focused?{}:{filter: "brightness(150%)"}}>
                    <img src={process.env.PUBLIC_URL + "/desktop/" + this.props.icon}
                    className="v2-window-title-icon" />
                    <div className="v2-window-title-text">
                        {this.props.title}
                    </div>
                    <div className="v2-window-button-container">
                        <button className="v2-window-title-button" onClick={this.props.minimize}>
                           ⎽
                        </button>
                        <button className="v2-window-title-button v2-window-title-close-button" onClick={this.props.close}>
                            Ｘ
                        </button>
                    </div>
                </div>
                <div className="v2-window-inner">
                    <div className="v2-window-body" style={{height: appHeight}}>
                        {this.props.children}
                    </div>

                </div>
            </div>
        );
    }
}
