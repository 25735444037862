import React, { Component } from "react";
import Message from "./Message";
import request from "../../services/request";
import info from "../../data/info";

export default class Contact extends Component {
    constructor(){
        super();
        this.state = {name: "", email: "", message: "", status: ""};
    }
    render() {
        let StatusMessage = null;
        if (this.state.status === "success")
            StatusMessage = <Message status={this.state.status} message="Sent Successfully!" />
        else if (this.state.status === "error")
            StatusMessage = <Message status={this.state.status} message="Send Failed!" />
        else StatusMessage = null;

        return (
            <div className="v2-contact">
                <div className="v2-title">
                    Contact Info
                </div>
                <div className="v2-contact-info">
                    <div className="v2-contact-info-key">Email:</div>
                    <div className="v2-contact-info-value">{info.email}</div>
                </div>
                <br />
                <div className="v2-title">
                    Message Me
                </div>
                <div className="v2-contact-messaging-container">
                    <input type="text" className="v2-text-input" placeholder="Name"
                    value={this.state.name} onChange={(event) => this.setState({name: event.target.value})}/>
                    <input type="text" className="v2-text-input" placeholder="Email"
                    value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}/>
                    <textarea rows="4" className="v2-text-area" placeholder="Message"
                    value={this.state.message} onChange={(event) => this.setState({message: event.target.value})}/>
                    <button className="v2-contact-button button" onClick={() => this.submit()}>Send</button>
                    {StatusMessage}
                </div>
            </div>
        );
    }

    submit() {
        if (this.state.name !== "" && this.state.email !== "" && this.state.message !== "") {
            let data = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            };
            request.post("/contact", data).then((response) => {
                this.setState({status: "success"});
            }).catch((error) => {
                this.setState({status: "error"});
	       });
        }
    }
}
