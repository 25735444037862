import React, { Component } from "react";
import StartMenuItem from "../../components/v2/StartMenuItem";

export default class StartMenu extends Component {
    render() {
        let apps = this.props.apps.map((app, index) => {
            return (
                <StartMenuItem key={index} icon={app.icon} text={app.title} click={() => this.props.click(app.name)}/>
            );
        });

        let links = this.props.links.map((app, index) => {
            return (
                <StartMenuItem key={index} icon={app.icon} text={app.title} click={() => this.props.click(app.name)}/>
            );
        });
        return (
            <div className="v2-start-menu">
                <div className="v2-start-menu-top-container">
                    <img className="v2-start-menu-user-image"
                    src={process.env.PUBLIC_URL + "/desktop/user.jpg"} />
                    <div className="v2-start-menu-user-name">
                        User
                    </div>
                </div>

                <div className="v2-start-menu-middle-container">
                    <div className="v2-start-menu-list">
                        {apps}
                    </div>
                    <div className="v2-start-menu-list v2-start-menu-list-right-side ">
                        {links}
                    </div>
                </div>
                <div className="v2-start-menu-bottom-container"></div>
            </div>
        );
    }
}
