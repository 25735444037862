import React, { Component } from "react";
import {Grid, TextField, Button} from "@material-ui/core";
import Message from "./../components/v1/Message";
import request from "./../services/request";
import navigator from "../services/navigator";

export default class Login extends Component {
    constructor(){
        super();
        this.state = {email: "", password: "", status: ""};
    }
    render() {
        let StatusMessage = null;
        if (this.state.status === "error")
            StatusMessage = <Message status={this.state.status} message="Unable to login" />
        return (
            <div className="v1-content">
                {StatusMessage}
                <Grid container spacing={0} direction="column"
                alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                    <TextField label="Email" value={this.state.email} InputProps={{className: "input"}}
                        InputLabelProps={{className: "input-label"}}
                        onChange={(event) => this.setState({email: event.target.value})} />
                    <TextField label="Password" value={this.state.password} InputProps={{className: "input"}}
                        type="password" InputLabelProps={{className: "input-label"}}
                        onChange={(event) => this.setState({password: event.target.value})} />
                    <Button variant="outlined" className="v1-button" onClick={() => this.submit()}>
                        Login
                    </Button>
                </Grid>

            </div>
        )
    }
    componentDidMount() {
        let token = localStorage.getItem('token');
        if (token) navigator.push("/admin");
    }
    submit() {
        if (this.state.email !== "" && this.state.password !== "") {
            let data = {
                email: this.state.email,
                password: this.state.password
            };
            request.post("/admin/login", data).then((response) => {
                localStorage.setItem('token', response.data.token);
                navigator.push("/admin");
            }).catch((error) => {
                this.setState({status: "error"});
	       });
        }
    }
}
