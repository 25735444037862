import React, { Component } from "react";

export default class StartMenuItem extends Component {
    render() {
        return (
            <button className="v2-start-menu-item" onClick={this.props.click}>
                <img className="v2-start-menu-item-icon"
                src={process.env.PUBLIC_URL + "/desktop/" + this.props.icon}/>
                <div className="v2-start-menu-item-text">{this.props.text}</div>
            </button>
        );
    }
};
