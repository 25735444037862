import React, { Component } from "react";
import Typist from "react-typist";
import info from "../../data/info";

export default class About extends Component {
    constructor(){
        super();
        this.state = {
            commands: []
        };

        this.interval = null;
    }
    render() {
        let commands = this.state.commands.map((command, index) => {
            return (
                <div key={index} className="v2-about-command">
                    <div className="v2-about-command-question">
                        {">"}
                        <Typist cursor={{hideWhenDone: true, hideWhenDoneDelay: 200}}>
                            {command.question}
                        </Typist>
                    </div>
                    <div className="v2-about-command-answer">
                        {command.answer}
                    </div>
                </div>
            );
        });
        return (
            <div className="v2-about">
                <div className="v2-about-text">
                    {commands}
                </div>
            </div>
        );
    }
    componentDidMount() {
        let counter = 0;
        let qna = [
            {question: "get name", answer: "Ivan Zhang"},
            {question: "get major", answer: "Computer Science"},
            {question: "get university", answer: "University of Western Ontario"},
            {question: "get more", answer: info.about},
        ];

        this.interval = setInterval(() => {
            let commands = this.state.commands;
            commands.push(qna[counter]);
            this.setState({commands});
            counter++;
            if (counter === qna.length) clearInterval(this.interval);

        }, 1000);
    }

    componentWillUnmount() {
        if (this.interval !== null) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
}
