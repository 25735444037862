import axios from "axios";
import navigator from "./navigator";

axios.defaults.baseURL = "https://ivanzhang.io/api/v1";
// axios.defaults.baseURL = "http://localhost:443/api/v1";

axios.interceptors.request.use(function(config) {
    let token = localStorage.getItem('token');
    if (token) config.headers.Authorization = 'Bearer ' + token;
    return config;
}, function(error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    //does not work
    if (error && error.response != undefined && error.response.status == 401) {
        localStorage.removeItem('token');
        navigator.push("/admin/login");
    }
    return Promise.reject(error);
});


export default axios;
