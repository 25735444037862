import React, { Component } from "react";
import {Grid} from "@material-ui/core";
import info from "../../data/info";

export default class About extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" className="v1-section">
                <div className="v1-subtitle">About Me</div>
                <Grid container direction="row" justify="center">
                    <p className="v1-paragraph">
                        {info.about}
                    </p>
                </Grid>
            </Grid>
        );
    }

}
