const info = {
    about: "Hello there, my name is Ivan Zhang and I am passionate about technology. "
        + "I have graduated with Bachelor of Computer Science from University of Western Ontario and currently working as software developer.",
    email: "me@ivanzhang.io",
    descriptions: {
        hubinternational: [
            "Developed features for an insurance quoting and buying application and for an insurance management system."
        ],
        tulip: [
            "Developed multitenant management system with Kubernetes on the Google Cloud Platform."
        ],
        freelance: [
            "Designed and developed websites for small businesses based on their budgets."
        ],
        comparely: "An app that allows users to compare grocery prices between different grocery stores.",
        chattybox: "A mobile chat app where users add others as friends, and they can send texts and images to each other",
        drawanything: "An Android app for drawing pictures with different brushes and colors.",
        mathsbot: "A notepad style calculator that is able to do complex calculations, conversions, integrals, and create charts.",
        pocketqr: "A mobile app that allows users to send money by scanning QR codes and sending data via HTTP or SMS.",
        tenty: "A mobile and a web app that prevents users from scam calls and texts by checking the phone numbers."
    },
    links: {
        hubinternational: [
            {label: "Website", url: "https://hubinternational.com"}
        ],
        tulip: [
            {label: "Website", url: "https://tulip.com"}
        ],
        freelance: [
            {label: "Website", url: "http://www.thewebdesigner.ca"}
        ],
        comparely: [
            {label: "Demo", url: "/demo/comparely"}
        ],
        chattybox: [
            {label: "Demo", url: "demo/chattybox"}
        ],
        drawanything: [
            {label: "Android", url: "https://play.google.com/store/apps/details?id=com.university.drawsomething"}
        ],
        mathsbot: [
            {label: "iOS", url: "https://itunes.apple.com/us/app/mathsbot/id1451030674?ls=1&mt=8"},
            {label: "Android", url: "https://play.google.com/store/apps/details?id=com.ivanzhang.mathsbot&hl=en"}
        ],
        pocketqr: [
            {label: "Devpost", url: "https://devpost.com/software/pocketqr"}
        ],
        tenty: [
            {label: "Devpost", url: "https://devpost.com/software/tenty"}
        ]
    },
    dates: {
        hubinternational: "April 2022 - Present",
        tulip: "September 2019 - September 2020",
        freelance: "July 2017 - August 2017",
        pocketqr: "January 2019",
        tenty: "November 2018"
    },
    companyNames: {
        hubinternational: "Hub International",
        tulip: "Tulip",
        freelance: "thewebdesigner.ca"
    },
    jobTitles: {
        hubinternational: "Software Developer",
        tulip: "Software Developer",
        freelance: "Freelance Web Developer"
    },
    projectNames: {
        comparely: "Comparely",
        chattybox: "ChattyBox",
        drawanything: "DrawAnything",
        mathsbot: "MathsBot",
        pocketqr: "PocketQR",
        tenty: "Tenty"
    },
    projectSignificances: {
        drawanything: "Over 50,000 downloads",
        mathsbot: "Over 2,000 downloads",
        pocketqr: "Won $2000 at Hack DeltaHacks V",
        tenty: "Won third place at Hack Western V"
    },

    images: {
        comparely: "comparely.png",
        chattybox: "chattybox.png",
        drawanything: "drawanything.png",
        mathsbot: "mathsbot.png",
        pocketqr: "pocketqr.png",
        tenty: "tenty.png"
    }
};

export default info;
