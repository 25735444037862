import React, { Component } from "react";
import {Grid} from "@material-ui/core/";

export default class Footer extends Component {
    render() {
        return (
            <Grid container className="v1-footer v1-primary-background-color">
                <Grid item sm={6}>
                    <Grid container direction="row" justify="flex-start">
                        <a className="v1-footer-text v1-link" href="https://devpost.com/CyaTmrw">Devpost</a>
                        <a className="v1-footer-text v1-link" href="https://github.com/CyaTmrw">Github</a>
                    </Grid>

                </Grid>
                <Grid item sm={6}>
                    <Grid container direction="row" justify="flex-end">
                        <div className="v1-footer-text">© Ivan Zhang {new Date().getFullYear()}</div>
                    </Grid>
                </Grid>
            </Grid>

        );
    }
}
