import React, { Component } from "react";
import { Router, Route} from "react-router";
import Config from "./pages/Config";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import HomeV1 from "./pages/v1/Home";
import RandomV1 from "./pages/v1/Random";
import HomeV2 from "./pages/v2/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Demo from "./pages/Demo";
import navigator from "./services/navigator";
import "./styles/v1/app.css";
import "./styles/v2/app.css";
import "./styles/admin.css";
import "./styles/game.css";
import "./styles/thanos.css";

export default class App extends Component {
    render() {
        return (
            <Router history={navigator}>
                <div>
                    <Route exact path="/" component={Config}/>
                    <Route exact path="/admin" component={Admin}/>
                    <Route exact path="/admin/login" component={Login}/>
                    <Route exact path="/v1" component={HomeV1}/>
                    {/* <Route exact path="/v1/random" component={RandomV1}/> */}
                    <Route exact path="/v2" component={HomeV2}/>
                    <Route exact path="/privacy-policy/mathsbot" component={() =>
                        <PrivacyPolicy app="MathsBot" />
                    }/>
                    <Route exact path="/privacy-policy/chattybox" component={() =>
                        <PrivacyPolicy app="ChattyBox" />
                    }/>
                    <Route exact path="/privacy-policy/comparely" component={() =>
                        <PrivacyPolicy app="Comparely" />
                    }/>
                    <Route exact path="/demo/chattybox" component={() =>
                        <Demo title="ChattyBox"
                            about={
                                "This a mobile chat app where users can add other users as friends, and they can send texts and images to each other. " +
                                "Users need to create an account with username, password, and profile picture in order to use app, but users have the ability to change their profile at any time. "
                            }
                            underworkings={
                                "Uses React Native for mobile frontend, Node.js and Express for the backend. SQLite is used to store frontend's data, while uses MySQL as the database for the backend. " +
                                "Sends messages, friend requests, and acceptances via web sockets using Socket.io, and sends notifications using Apple Push Notification for iOS and Google Cloud Messaging for Android. " +
                                "Hosted on Heroku and stores images in AWS S3. " +
                                "Other notable mentions, encrypts passwords with bcrypt before storing, and maintains sessions with JSON Web Token."
                            }
                            images={["chattybox_one.png", "chattybox_two.png", "chattybox_three.png"]}
                            video={"chattybox.mov"}
                        />
                    }/>
                    <Route exact path="/demo/comparely" component={() =>
                        <Demo title="Comparely"
                            about={
                                "A mobile app that compares prices between different grocery stores. Users are able search and filter by stores and prices. " +
                                "Users can add items that they want to buy into a shopping list. "
                            }
                            underworkings={
                                "Uses React Native for mobile frontend, Node.js and Express for the backend. SQLite is used to store frontend's data, while uses MySQL as the database for the backend. " +
                                "Scrapes data from grocery store websites weekly by using Puppeteer and parsing the HTML to get relevant information. " +
                                "Store these information into a database for whenever the mobile frontend makes an API call to get these data."
                            }
                            images={["comparely_one.png", "comparely_two.png", "comparely_three.png"]}
                            video={"comparely.mov"}
                        />
                    }/>
                </div>
            </Router>
        );
    }
}
