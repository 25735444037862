import React, { Component } from "react";
import {IconButton, Drawer, Hidden, List, ListItem, Button, Grid} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import navigator from "../../services/navigator";

export default class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {toggle: false};
    }
    render() {
        return (
            <div className="v1-navbar">
                <Drawer anchor="left" open={this.state.toggle} onClose={() => this.toggle(false)}
                     classes={{paper: "v1-drawer"}}>
                    <div tabIndex={0} role="button" onClose={() => this.toggle(false)}
                        onKeyDown={() => this.toggle(false)}>
                        <List>
                            <ListItem button onClick={() => this.props.scroll("about")}>
                                <div className="v1-drawer-item-text">About</div>
                            </ListItem>
                            <ListItem button onClick={() => this.props.scroll("projects")}>
                                <div className="v1-drawer-item-text">Projects</div>
                            </ListItem>
                            <ListItem button onClick={() => this.props.scroll("contact")}>
                                <div className="v1-drawer-item-text">Contact</div>
                            </ListItem>
                            {/* <ListItem button onClick={() => {
                                this.props.animate("out");
                                setTimeout(() => navigator.push("/v1/random"), 800);
                            }}>
                                <div className="v1-drawer-item-text">Random</div>
                            </ListItem> */}
                        </List>
                    </div>
                </Drawer>
                <Grid container>
                    <Grid item>
                        <Hidden xsDown>
                            <div className="v1-navbar-button-container">
                                <button className="v1-navbar-button" onClick={() => this.props.scroll("about")}>
                                    About
                                </button>
                                <button className="v1-navbar-button" onClick={() => this.props.scroll("projects")}>
                                    Projects
                                </button>
                                <button className="v1-navbar-button" onClick={() => this.props.scroll("contact")}>
                                    Contact
                                </button>
                                {/* <button className="v1-navbar-button" onClick={() => {
                                    this.props.animate("out");
                                    setTimeout(() => navigator.push("/v1/random"), 800);
                                }}>
                                    Random
                                </button> */}
                            </div>
                        </Hidden>
                    </Grid>
                    <Grid item>
                        <Hidden smUp>
                            {(this.state.toggle) ?
                                null : (
                                    <IconButton aria-label="Search" onClick={() => this.toggle()}>
                                        <MenuIcon className="v1-menu-icon"/>
                                    </IconButton>
                                )
                            }

                        </Hidden>
                    </Grid>
                </Grid>
            </div>

        );
    }
    toggle(boolean) {
        if (boolean === undefined) this.setState({ toggle: !this.state.toggle});
        else this.setState({ toggle: boolean});
    };
}
