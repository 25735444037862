import React, { Component } from "react";

export default class LandingImage extends Component {
    constructor(){
        super();
    }
    render() {
        return (
            <div className="v1-landing-image-container">
                <div className="v1-landing-image-inner">
                    <div className="v1-landing-content">
                        <div className="v1-title">Random Stuff</div>
                    </div>
                </div>
            </div>
        );
    }
}
