import React, { Component } from "react";
import {Grid} from "@material-ui/core";
import Player from "react-player";

export default class Demo extends Component {
    render() {
        return (
            <div className="v1-content">
                <div className="v1-title">{this.props.title}</div>
                <div className="v1-subtitle">About</div>
                <Grid container direction="row" justify="center">
                    <p className="v1-paragraph">
                        {this.props.about}
                    </p>
                </Grid>
                <div className="v1-subtitle">Demo</div>
                <Grid container direction="row" justify="center">
                    <Player url={"/demos/"+this.props.video} height="750px" playing loop controls/>
                </Grid>
                <br/>
                <Grid container direction="row" justify="center">
                    {
                        this.props.images.map((image) => {
                            return (
                                <Grid item key={image} style={{padding: 10}}>
                                    <img src={"/demos/"+image} style={{width: 300}} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <br/>
                <div className="v1-subtitle">How does it work?</div>
                <Grid container direction="row" justify="center">
                    <p className="v1-paragraph">
                        {this.props.underworkings}
                    </p>
                </Grid>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        )
    }
}
