import React, { Component } from "react";
import {Grid, Button, Card, CardActions, CardContent} from "@material-ui/core";

export default class ExperienceCard extends Component {
    render() {
        let buttons = this.props.links.map((link, key) => (
            <Button key={key} size="medium" variant="outlined" href={link.url} color="primary"
                style={{textTransform: "none", color: "#00a1ff", borderColor: "#00a1ff"}}>
              {link.label}
            </Button>
        ));

        let description = this.props.description;

        if (Array.isArray(this.props.description)) {
            description = this.props.description.map((text, index) => (
                <span key={index}>
                  - {text} <br/>
                </span>
            ));
        }

        return (
            <Card className="v1-experience-card">
                <Grid container direction="column" >
                    <Grid container direction="row">
                        {
                            this.props.image &&
                            <img alt={this.props.title} height="120" width="120"
                            src={process.env.PUBLIC_URL + "/static/"+this.props.image}/>
                        }
                        <CardContent className="v1-card-content">
                            <div className="v1-card-title">{this.props.title}</div>
                            <div className="v1-card-subtitle">{this.props.subtitle}</div>
                            <div className="v1-card-date">{this.props.date}</div>
                            <CardActions>
                                {buttons}
                            </CardActions>
                        </CardContent>
                    </Grid>
                    <CardContent className="v1-card-discription">
                        <p className="v1-card-paragraph">{description}</p>
                    </CardContent>
                </Grid>
            </Card>
        );
    }
}
