import React, { Component } from "react";
import {Grid, TextField, Button} from "@material-ui/core/";
import Message from "./Message";
import request from "../../services/request";
import info from "../../data/info";

export default class Contact extends Component {
    constructor(){
        super();
        this.state = {name: "", email: "", message: "", status: ""};
    }
    render() {
        let StatusMessage = null;
        if (this.state.status === "success")
            StatusMessage = <Message status={this.state.status} message="Sent Successfully!" />
        else if (this.state.status === "error")
            StatusMessage = <Message status={this.state.status} message="Send Failed!" />
        else StatusMessage = null;
        return (
            <Grid container direction="column" justify="center" className="v1-section">
                <div className="v1-subtitle">Contact</div>
                <Grid container direction="row" justify="center" className="v1-contact">
                    <Grid item className="v1-contact-subsection">
                        <div className="v1-contact-subtitle">Message me</div>
                        <Grid container direction="column" justify="center">
                            {StatusMessage}
                            <TextField id="name" label="Name" value={this.state.name}
                                className="v1-text-field" InputProps={{className: "v1-input"}}
                                InputLabelProps={{className: "v1-input-label"}}
                                onChange={(event) => this.setState({name: event.target.value})} />
                            <TextField id="email" label="Email" value={this.state.email}
                                className="v1-text-field" InputProps={{className: "v1-input"}}
                                InputLabelProps={{className: "v1-input-label"}}
                                onChange={(event) => this.setState({email: event.target.value})} />
                            <TextField id="message" label="Message" value={this.state.message} multiline
                                rowsMax="8" className="v1-text-field" InputProps={{className: "v1-input"}}
                                InputLabelProps={{className: "v1-input-label"}}
                                onChange={(event) => this.setState({message: event.target.value})}/>
                                <Button variant="outlined" className="v1-button" onClick={() => this.submit()}>
                                    Send
                                </Button>
                        </Grid>
                    </Grid>
                    <Grid item className="v1-contact-subsection">
                        <div className="v1-contact-subtitle">Info</div>
                        <div className="v1-contact-text">Email:
                            <div>{info.email}</div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    submit() {
        if (this.state.name !== "" && this.state.email !== "" && this.state.message !== "") {
            let data = {
                name: this.state.name,
                email: this.state.email,
                message: this.state.message
            };
            request.post("/contact", data).then((response) => {
                this.setState({status: "success"});
            }).catch((error) => {
                this.setState({status: "error"});
	       });
        }
    }
}
