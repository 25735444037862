import React, { Component } from "react";
import {Grid} from "@material-ui/core";
import ExperienceCard from "./ExperienceCard";
import info from "../../data/info";

export default class Experiences extends Component {
    render() {
        return (
            <Grid container direction="column" justify="center" className="v1-section">
                <div className="v1-subtitle">Experiences</div>
                <Grid container direction="column" alignItems="center">
                    <ExperienceCard links={info.links.hubinternational} 
                        title={info.companyNames.hubinternational} subtitle={info.jobTitles.hubinternational}
                        date={info.dates.hubinternational} description={info.descriptions.hubinternational} />
                    <ExperienceCard links={info.links.tulip}
                        title={info.companyNames.tulip} subtitle={info.jobTitles.tulip}
                        date={info.dates.tulip} description={info.descriptions.tulip} />
                    <ExperienceCard links={info.links.freelance}
                        title={info.companyNames.freelance} subtitle={info.jobTitles.freelance}
                        date={info.dates.freelance} description={info.descriptions.freelance}/>
                </Grid>
            </Grid>
        );
    }

}
